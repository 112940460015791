import { useParams } from "react-router-dom";
import React, {useEffect, useState} from "react";
import CustomerData from "../../resources/data/customer/customer.json";
import Parser from "html-react-parser";

export default function Result() {
    let [items1, setItems1] = useState(null);
    let [items2, setItems2] = useState(null);
    let params = useParams();

    useEffect(() => {
        setItems1(CustomerData.customer.result.vending.sort(function (a, b) {
            if (a.seq > b.seq) {
                return -1;
            } else if (a.seq > b.seq) {
                return 1;
            }
            return 0;
        }))
        setItems2(CustomerData.customer.result.access.sort(function (a, b) {
            if (a.seq > b.seq) {
                return -1;
            } else if (a.seq > b.seq) {
                return 1;
            }
            return 0;
        }))
    }, []);

    return (
        <>
            <link rel="stylesheet" type="text/css" href="/data/product2/skin/prdBasic/style.css"/>
            <link rel="stylesheet" type="text/css" href="/comm/css/sub.css"/>
            <div id="visual" className="subtop_product" style={{opacity: "1"}}>
                <p></p>
                <h3><span>고객지원</span>CS CENTER</h3>
            </div>

            <div id="contents" style={{opacity: "1"}}>
                <div id="sticker">
                    <article className="NavMenu">
                        <div>
                            <nav className="mobile_snb">
                                <div className="tbox clearfix">
                                    <a href="/" className="home"></a>
                                    <div className="box dep1">
                                        <div className="dropLst">
                                            <a href="#" className="txt title">고객지원</a>
                                            <div className="dlst">
                                                <ul>
                                                    <li><a href="/product/product" className="">제품</a></li>
                                                    <li><a href="/service/umms" className="">서비스</a></li>
                                                    <li><a href="/customer/notice" className="on">고객지원</a></li>
                                                    <li><a href="/company/about" className="">회사소개</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box dep2">
                                        <div className="dropLst">
                                            <a href="#" className="txt title">{params.type.toUpperCase()}</a>
                                            <div className="depth2">
                                                <ul>
                                                    <li><a href="/customer/notice" className="">공지사항</a></li>
                                                    <li><a href="/customer/qna" className="">문의하기</a></li>
                                                    <li><a href="/customer/result" className="on">설치사례</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </article>
                </div>

                <div className="contentArea_wide">
                    <div className="tit_area">
                        <div className="location">
                            <a href="/"><img src="/img/lnbHome.png" alt="Home"/></a>
                            <img src="/img/lnbArrow.png" alt=">"/>
                            <a href="/product/total" className="here_link">고객지원</a><img
                            src="/img/lnbArrow.png" alt=">"/>{params.type.toUpperCase()}
                        </div>
                        <h3 className="subcon_title">설치사례</h3>
                    </div>
                    <div className="contents_in">
                        <div className="width1180">
                            <div className="pro_list pro_result">
                                {items1 && items1.map(item => (
                                    <dl>
                                        {Parser(item.installInfo)}
                                    </dl>
                                ))}
                                {items2 && items2.map(item => (
                                    <dl>
                                        {Parser(item.installInfo)}
                                    </dl>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
