import axios from 'axios';

const API = axios.create({
    baseURL: `https://devapi.ubcn.co.kr:17881`,
    //baseURL : `https://chris.ubcn.co.kr:17881`,
    timeout: 30000,
    headers: {
        "Content-Type" : `application/json;charset=UTF-8`,
        "Access-Control-Allow-Origin": "*",
        "Accept": "application/json",
    }
});
export default API;
