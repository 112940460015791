import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Parser from "html-react-parser";

export default function Privacy() {
    let params = useParams();
    return (
        <>
            <link rel="stylesheet" type="text/css" href="/comm/css/sub.css"/>
            <div id="visual" className="subtop_product" style={{opacity: "1"}}>
                <p></p>
                <h3><span>이용안내</span>USER GUIDE</h3>
            </div>

            <div id="contents" style={{opacity: "1"}}>
                <div id="sticker">
                    <article className="NavMenu">
                        <div>
                            <nav className="mobile_snb">
                                <div className="tbox clearfix">
                                    <a href="/" className="home"></a>
                                    <div className="box dep1">
                                        <div className="dropLst">
                                            <a href="#" className="txt title">이용안내</a>
                                            <div className="dlst">
                                                <ul>
                                                    <li><a href="/product/total" className="">제품</a></li>
                                                    <li><a href="/service/umms" className="">서비스</a></li>
                                                    <li><a href="/company/about" className="">회사소개</a></li>
                                                    <li><a href="/other/privacy" className="">개인정보처리방침</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box dep2">
                                        <div className="dropLst">
                                            <a href="#" className="txt title">{params.type.toUpperCase()}</a>
                                            <div className="depth2">
                                                <ul>
                                                    <li>
                                                        <a href={"/other/privacy"} className={"on"}>개인정보취급방침</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/other/sitemap"} className={""}>사이트맵</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </article>
                </div>
                <div className="contentArea_wide">
                    <div className="tit_area">
                        <div className="location">
                            <a href="/">
                                <img src="/img/lnbHome.png" alt="Home"/>
                            </a>
                            <img src="/img/lnbArrow.png" alt=">"/>
                            <a href="about.jsp" className="here_link">이용안내</a>
                            <img src="/img/lnbArrow.png" alt=">"/>
                            개인정보처리방침
                        </div>
                        <h3 className="subcon_title">개인정보처리방침</h3>
                    </div>
                    <div className="contents_in">
                        <div className="policy_con width1180">
                            "유비씨엔(주)"는 (이하 "회사"는) 고객님의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.
                            우리 회사는 개인정보처리방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고
                            있는지
                            알려드립니다.<br/>
                            우리 회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.<br/><br/>
                            본 방침은 : 2021년 01월 01일 부터 시행됩니다.<br/><br/>

                            <ul className="privacy_list">
                                <li><a href="#num1">1. 수집하는 개인정보 항목</a></li>
                                <li><a href="#num2">2. 개인정보의 수집 및 이용목적</a></li>
                                <li><a href="#num3">3. 개인정보의 보유 및 이용기간</a></li>
                                <li><a href="#num4">4. 개인정보의 파기절차 및 방법</a></li>
                                <li><a href="#num5">5. 개인정보 제공</a></li>
                                <li><a href="#num6">6. 수집한 개인정보의 위탁</a></li>
                                <li><a href="#num7">7. 정보주체와 법정대리인의 권리 및 의무 및 행사방법</a></li>
                                <li><a href="#num8">8. 개인정보 자동수집 장치의 설치, 운영, 거부</a></li>
                                <li><a href="#num9">9. 개인정보에 관한 민원서비스</a></li>
                            </ul>

                            <a name="num1"></a>
                            <p className="cont_tit">수집하는 개인정보 항목 </p>
                            <div className="cont_txt">
                                우리 회사는 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.

                                <div className="cont_box">
                                    <strong>수집항목</strong><br/>
                                    로그인ID, 비밀번호, 비밀번호확인, 이름, 생년월일, 전화번호, 휴대폰, 회사전화, 이메일, 주소, 쿠키<br/><br/>
                                    <strong>개인정보 수집방법</strong><br/>회원가입()
                                </div>
                            </div>

                            <a name="num2"></a>
                            <p className="cont_tit">개인정보의 수집 및 이용목적 </p>
                            <div className="cont_txt">
                                우리 회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br/>

                                <div className="cont_box">
                                    <strong>서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산</strong><br/>
                                    콘텐츠 제공 , 구매 및 요금 결제 , 물품배송 또는 청구지 등 발송 , 금융거래 본인 인증 및 금융 서비스 <br/><br/>

                                    <strong>회원 관리</strong><br/>
                                    회원제 서비스 이용에 따른 본인확인 , 개인 식별 , 불량회원의 부정 이용 방지와 비인가 사용 방지 , 가입 의사 확인 , 연령확인 , 불만처리
                                    등 민원처리 ,
                                    고지사항 전달 <br/><br/>

                                    <strong>마케팅 및 광고에 활용</strong><br/>
                                    이벤트 등 광고성 정보 전달 , 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계
                                </div>
                            </div>

                            <a name="num3"></a>
                            <p className="cont_tit">개인정보의 보유 및 이용기간</p>
                            <div className="cont_txt">
                                원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우
                                회사는 아래와 같이
                                관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.

                                <div className="cont_box">
                                    - 보존 항목 : 결제기록<br/>
                                    - 보존 근거 : 계약 또는 청약철회 등에 관한 기록<br/>
                                    - 보존 기간 : 3년<br/>
                                    - 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)<br/>
                                    - 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)<br/>
                                    - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)
                                </div>
                            </div>

                            <a name="num4"></a>
                            <p className="cont_tit">개인정보의 파기절차 및 방법 </p>
                            <div className="cont_txt">
                                회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다.<br/>
                                파기절차 및 방법은 다음과 같습니다.<br/>

                                <div className="cont_box">
                                    <strong>파기절차</strong><br/>
                                    회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에
                                    의한 정보보호 사유에
                                    따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어 집니다. <br/>
                                    별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다. <br/><br/>

                                    <strong>파기방법</strong> <br/>
                                    - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
                                </div>
                            </div>

                            <a name="num5"></a>
                            <p className="cont_tit">개인정보 제공 </p>
                            <div className="cont_txt">
                                회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<br/>

                                <div className="cont_box">
                                    - 이용자들이 사전에 동의한 경우<br/>
                                    - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
                                </div>
                            </div>

                            <a name="num6"></a>
                            <p className="cont_tit">수집한 개인정보의 위탁 </p>
                            <div className="cont_txt">
                                우리 회사는 서비스 이행을 위해 아래와 같이 외부 전문업체에 위탁하여 운영하고 있습니다. <br/>

                                <div className="cont_box">
                                    - 위탁 대상자 : 주식회사 쓰리웨이<br/>
                                    - 위탁업무 내용 : 웹사이트 및 시스템 관리
                                </div>
                            </div>

                            <a name="num7"></a>
                            <p className="cont_tit">정보주체와 법정대리인의 권리 및 의무 및 행사방법</p>
                            <div className="cont_txt">
                                정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
                                <div className="cont_box">
                                    고객서비스담당 부서 : 김대식<br/><br/>
                                    1. 개인정보 열람요구<br/>
                                    2. 오류 등이 있을 경우 정정 요구 <br/>
                                    3. 삭제요구 <br/>
                                    4. 처리정지 요구<br/>
                                </div>
                                ※ 만 14세 미만 아동의 경우, 법정대리인이 아동의 개인정보를 조회하거나 수정할 권리, 수집 및 이용 동의를 철회할 권리를 가집니다.
                            </div>

                            <a name="num8"></a>
                            <p className="cont_tit">개인정보 자동수집 장치의 설치, 운영, 거부</p>
                            <div className="cont_txt">
                                회사는 귀하의 정보를 수시로 저장하고 찾아내는 "쿠키(cookie)’ 등을 운용합니다. 쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 귀하의
                                브라우저에 보내는 아주
                                작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키를 사용합니다. <br/>

                                <div className="cont_box">
                                    <strong>▶ 쿠키란 무엇일까요? </strong> <br/>
                                    쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터에 저장됩니다. <br/><br/>

                                    <strong>▶ 쿠키 등 사용 목적</strong> <br/>
                                    쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 보다 빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스 개선에 활용합니다. 이를
                                    통해 이용자는 보다
                                    손쉽게 서비스를 이용할 수 있게 됩니다.<br/><br/>


                                    <strong>▶ 쿠키를 수집하지 못하게 거부하고 싶다면? </strong> <br/>
                                    이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
                                    저장될 때마다 확인을
                                    거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다. 다만 쿠키 설치를 거부할 경우 웹 사용이 불편해지며 로그인이 필요한 일부 서비스 이용에
                                    어려움이 있을 수
                                    있습니다.<br/><br/>

                                    <strong>▶ 설정 방법의 예 </strong> <br/>
                                    1) Internet Explorer의 경우 : 웹 브라우저 상단의 도구 메뉴 &gt; 인터넷 옵션 &gt; 개인정보 &gt; 설정<br/>
                                    2) Chrome의 경우 : 웹 브라우저 우측의 설정 메뉴 &gt; 화면 하단의 고급 설정 표시 &gt; 개인정보의 콘텐츠 설정
                                    버튼 &gt; 쿠키
                                </div>
                            </div>

                            <a name="num9"></a>
                            <p className="cont_tit">개인정보에 관한 민원서비스</p>
                            <div className="cont_txt">
                                우리 회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고
                                있습니다. <br/>

                                <div className="cont_box">
                                    고객서비스담당 부서 : 김대식<br/>
                                    전화번호 : 1544-5737<br/><br/>

                                    개인정보관리책임자 성명 : 김대식<br/>
                                    전화번호 : 1544-5737<br/>
                                </div>
                                <br/>
                                    귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는
                                    이용자들의 신고사항에 대해
                                    신속하게 충분한 답변을 드릴 것입니다. <br/>
                                    기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다. <br/>

                                    <div className="cont_box">
                                        1.개인정보침해신고회사 (http://privacy.kisa.or.kr/국번없이 118) <br/>
                                        2.정보보호마크인증위원회 (http://www.eprivacy.or.kr/02-580-0533~4) <br/>
                                        3.대검찰청 인터넷범죄수사회사 (http://icic.sppo.go.kr/02-3480-3600) <br/>
                                        4.경찰청 사이버테러대응회사 (http://www.ctrc.go.kr/02-392-0330)
                                    </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
);
}