import {useState, useEffect} from 'react'
import ReactLoading from 'react-loading';
import '../resources/css/loading.css';
import $ from 'jquery';

function Loader(props) {
    const [data,setData]=useState({
        type : null,
        background : null,
        color : null,
        message : null,
        position : 'absolute',
    })
    useEffect(()=>{
        setData({
            type : props.type,
            background : props.background,
            color : props.color,
            message : props.message,
            position: props.position
        })
    }, [])
    $('.loading-base').css('background',data.background)
    $('.loading-title').css('color',data.color)

    return (
        <div className="contentWrap">
            <div align={"center"} className="loading-base" id={data.position}>
                <div className="loading-content">
                    <h3 className="loading-title">{data.message}</h3>
                    <ReactLoading type={data.type} color={data.color} height={'25%'} width={'25%'} />
                </div>
            </div>
        </div>
    );
}
export default Loader;

