import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import CompanyData from "../../resources/data/company/company.json";
import Parser from "html-react-parser";

export default function Partner() {
    let [items, setItems] = useState(null);
    let params = useParams();

    useEffect(() => {
        setItems(CompanyData.company.partner.sort(function (a, b) {
            if (a.seq > b.seq) {
                return -1;
            } else if (a.seq > b.seq) {
                return 1;
            }
            return 0;
        }))
    }, []);
        return (
            <>
                <script language="text/javascript" src="/data/js/lib2.js"></script>
                <link rel="stylesheet" type="text/css" href="/comm/css/sub.css"/>
                <link rel="stylesheet" type="text/css" href="/data/bbs/skin/partnerBasic/style.css"/>
                <div id="visual" className="subtop_product" style={{opacity: "1"}}>
                    <p></p>
                    <h3><span>서비스</span>SERVICE</h3>
                </div>

                <div id="contents" style={{opacity: "1"}}>
                    <div id="sticker">
                        <article className="NavMenu">
                            <div>
                                <nav className="mobile_snb">
                                    <div className="tbox clearfix">
                                        <a href="/" className="home"></a>
                                        <div className="box dep1">
                                            <div className="dropLst">
                                                <a href="#" className="txt title">회사소개</a>
                                                <div className="dlst">
                                                    <ul>
                                                        <li><a href="/product/total" className="">제품</a></li>
                                                        <li><a href="/service/umms" className="">서비스</a></li>
                                                        <li><a href="/company/about" className="">회사소개</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box dep2">
                                            <div className="dropLst">
                                                <a href="#" className="txt title">{params.type.toUpperCase()}</a>
                                                <div className="depth2">
                                                    <ul>
                                                        <li>
                                                            <a href={"/company/about"}>기업개요</a>
                                                        </li>
                                                        <li>
                                                            <a href={"/company/history"}>연혁</a>
                                                        </li>
                                                        <li>
                                                            <a href={"/company/partner"} className={"on"}>고객사</a>
                                                        </li>
                                                        <li>
                                                            <a href={"/company/location"}>찾아오시는 길</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </article>
                    </div>
                    <div className="contentArea_wide">
                        <div className="tit_area">
                            <div className="location">
                                <a href="/">
                                    <img src="/img/lnbHome.png" alt="Home"/>
                                </a>
                                <img src="/img/lnbArrow.png" alt=">"/>
                                <a href="about.jsp" className="here_link">회사소개</a>
                                <img src="/img/lnbArrow.png" alt=">"/>
                                고객사
                            </div>
                            <h3 className="subcon_title">고객사</h3>
                        </div>
                        <div className="contents_in">
                            <div className="width1180">
                                <h4 className="sub_title">우리와 함께하는 고객사 입니다.</h4>
                                <div className="photo_list">
                                    {items && items.map(item => (
                                        <>
                                            {Parser(item.tagData)}
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }