import {useParams, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import About from "../company/About";
import History from "../company/History";
import Partner from "../company/Partner";
import Location from "./Location";

export default function Company() {
    let navigate = useNavigate();
    let params = useParams();

    //페이지 접근 가능한 페이지 지정
    const [pages] = useState(["about", "history", "partner", "location"]);
    useEffect(() => {
        if (!pages.includes(params.type.toLowerCase())) {
            navigate("/customer/notice")
        }
    }, []);

    return (
        <>
            {params.type.toLowerCase() === "about" ? <About/> : null}
            {params.type.toLowerCase() === "history" ? <History/> : null}
            {params.type.toLowerCase() === "partner" ? <Partner/> : null}
            {params.type.toLowerCase() === "location" ? <Location/> : null}


        </>
    )
        ;
}