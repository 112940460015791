import React from "react";
import {useParams} from "react-router-dom";

export default function Sitemap() {
    let params = useParams();
        return (
            <>
                <link rel="stylesheet" type="text/css" href="/comm/css/sub.css"/>
                <div id="visual" className="subtop_product" style={{opacity: "1"}}>
                    <p></p>
                    <h3><span>이용안내</span>USER GUIDE</h3>
                </div>

                <div id="contents" style={{opacity: "1"}}>
                    <div id="sticker">
                        <article className="NavMenu">
                            <div>
                                <nav className="mobile_snb">
                                    <div className="tbox clearfix">
                                        <a href="/" className="home"></a>
                                        <div className="box dep1">
                                            <div className="dropLst">
                                                <a href="#" className="txt title">이용안내</a>
                                                <div className="dlst">
                                                    <ul>
                                                        <li><a href="/product/total" className="">제품</a></li>
                                                        <li><a href="/service/umms" className="">서비스</a></li>
                                                        <li><a href="/company/about" className="">회사소개</a></li>
                                                        <li><a href="/other/privacy" className="">개인정보처리방침</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box dep2">
                                            <div className="dropLst">
                                                <a href="#" className="txt title">{params.type.toUpperCase()}</a>
                                                <div className="depth2">
                                                    <ul>
                                                        <li>
                                                            <a href={"/other/privacy"} className={""}>개인정보취급방침</a>
                                                        </li>
                                                        <li>
                                                            <a href={"/other/sitemap"} className={"on"}>사이트맵</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </article>
                    </div>
                    <div className="contentArea_wide">
                        <div className="tit_area">
                            <div className="location">
                                <a href="/">
                                    <img src="/img/lnbHome.png" alt="Home"/>
                                </a>
                                <img src="/img/lnbArrow.png" alt=">"/>
                                <a href="about.jsp" className="here_link">이용안내</a>
                                <img src="/img/lnbArrow.png" alt=">"/>
                                사이트맵
                            </div>
                            <h3 className="subcon_title">사이트맵</h3>
                        </div>
                        <div className="contents_in">
                            <div className="sitemap_cont width1180">
                                <dl>
                                    <dt>제품</dt>
                                    <dd><a href="/product/total">Total</a></dd>
                                    <dd><a href="/product/vending">Vending</a></dd>
                                    <dd><a href="/product/access_control">Access Control</a></dd>
                                    <dd><a href="/product/ic_reader">IC Reader</a></dd>
                                    <dd><a href="/product/rf_reader">RF Reader</a></dd>
                                    <dd><a href="/product/multipad">MultiPAD</a></dd>
                                    <dd><a href="/product/afc">AFC</a></dd>
                                </dl>
                                <dl>
                                    <dt>서비스</dt>
                                    <dd><a href="/service/umms">무인기기 관리</a></dd>
                                    <dd><a href="/service/ecps">사원카드 결제</a></dd>
                                    <dd><a href="/service/usms">신용카드 출입</a></dd>
                                    <dd><a href="/service/api">데이터 연동</a></dd>
                                </dl>
                                <dl>
                                <dt>고객지원</dt>
                                    <dd><a href="/customer/data">공지사항</a></dd>
                                    <dd><a href="/customer/qna">소통의 장</a></dd>
                                    <dd><a href="/customer/result">설치사례</a></dd>

                                </dl>
                                <dl>
                                    <dt>회사소개</dt>
                                    <dd><a href="/company/about">기업개요</a></dd>
                                    <dd><a href="/company/history">연혁</a></dd>
                                    <dd><a href="/company/partner">고객사</a></dd>
                                    <dd><a href="/company/location">찾아오시는 길</a></dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
