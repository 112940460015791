import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import $ from "jquery";

export default function Location() {
    let params = useParams();
    const executeScript = () => {
        //alert("executeScript");
        const scriptTag = document.createElement("script");
        const inlineScript = document.createTextNode(`new daum.roughmap.Lander({
    	"timestamp" : "1617237966899",
    	"key" : "255ik"
    }).render();`);
        scriptTag.appendChild(inlineScript);
        document.body.appendChild(scriptTag);
    };

    // <!-- 2. 설치 스크립트 * 지도 퍼가기 서비스를 2개 이상 넣을 경우, 설치 스크립트는 하나만 삽입합니다. -->
    // document.write 문제가 발생해서 해당 파일을 직접 가져온다음 수정했음
    const InstallScript = () => {
        (function () {
            //alert("installScript");
            var c = window.location.protocol == "https:" ? "https:" : "http:";
            var a = "16137cec";

            if (window.daum && window.daum.roughmap && window.daum.roughmap.cdn) {
                return;
            }
            window.daum = window.daum || {};
            window.daum.roughmap = {
                cdn: a,
                URL_KEY_DATA_LOAD_PRE: c + "//t1.daumcdn.net/roughmap/",
                url_protocal: c,
            };
            var b =
                c +
                "//t1.daumcdn.net/kakaomapweb/place/jscss/roughmap/" +
                a +
                "/roughmapLander.js";

            // document.write -> doumnet.body.append로 수정
            const scriptTag = document.createElement("script");
            scriptTag.src = b;
            document.body.append(scriptTag);
            scriptTag.onload = () => {
                executeScript();
            };
        })();
    };

    useEffect(() => {
        InstallScript();
    }, []);
    return (
        <>
            <script charSet="UTF-8" className="daum_roughmap_loader_script"
                    src="https://ssl.daumcdn.net/dmaps/map_js_init/roughmapLoader.js"></script>
            <script charSet="UTF-8"
                    src="http://t1.daumcdn.net/kakaomapweb/place/jscss/roughmap/b88f2e2f/roughmapLander.js"></script>
            <script language="text/javascript" src="/data/js/lib2.js"></script>
            <link rel="stylesheet" type="text/css" href="/comm/css/sub.css"/>
            <link rel="stylesheet" type="text/css" href="/data/bbs/skin/partnerBasic/style.css"/>
            <div id="visual" className="subtop_product" style={{opacity: "1"}}>
                <p></p>
                <h3><span>서비스</span>SERVICE</h3>
            </div>

            <div id="contents" style={{opacity: "1"}}>
                <div id="sticker">
                    <article className="NavMenu">
                        <div>
                            <nav className="mobile_snb">
                                <div className="tbox clearfix">
                                    <a href="/" className="home"></a>
                                    <div className="box dep1">
                                        <div className="dropLst">
                                            <a href="#" className="txt title">회사소개</a>
                                            <div className="dlst">
                                                <ul>
                                                    <li><a href="/product/total" className="">제품</a></li>
                                                    <li><a href="/service/umms" className="">서비스</a></li>
                                                    <li><a href="/company/about" className="">회사소개</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box dep2">
                                        <div className="dropLst">
                                            <a href="#" className="txt title">{params.type.toUpperCase()}</a>
                                            <div className="depth2">
                                                <ul>
                                                    <li>
                                                        <a href={"/company/about"}>기업개요</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/company/history"}>연혁</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/company/partner"}>고객사</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/company/location"} className={"on"}>찾아오시는 길</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </article>
                </div>
                <div className="contentArea_wide">
                    <div className="tit_area">
                        <div className="location">
                            <a href="/">
                                <img src="/img/lnbHome.png" alt="Home"/>
                            </a>
                            <img src="/img/lnbArrow.png" alt=">"/>
                            <a href="about.jsp" className="here_link">회사소개</a>
                            <img src="/img/lnbArrow.png" alt=">"/>
                            찾아오시는 길
                        </div>
                        <h3 className="subcon_title">찾아오시는 길</h3>
                    </div>
                    <div className="contents_in">
                        <div className="location_con width1180">
                            <div className="map">
                                <div id="daumRoughmapContainer1617237966899"
                                     className="root_daum_roughmap root_daum_roughmap_landing os_mac"
                                     style={{width: "100%"}}>
                                    <div className="wrap_controllers hide">
                                        <a className="tit_controllers" href="https://map.kakao.com" target="_blank">
                                            <img
                                                src="//t1.daumcdn.net/localimg/localimages/07/2018/pc/common/logo_kakaomap.png"
                                                width="72" height="16" alt="카카오맵"
                                                style={{display:"block", width:"72px", height:"16px"}}/>
                                        </a>
                                        <div className="wrap_btn_roadview">
                                            <a className="txt" target="_blank"
                                               href="https://map.kakao.com/?from=roughmap&amp;eName=%EC%84%9C%EC%9A%B8%20%EA%B8%88%EC%B2%9C%EA%B5%AC%20%EA%B0%80%EC%82%B0%EB%94%94%EC%A7%80%ED%84%B81%EB%A1%9C%20212&amp;eX=473594.0&amp;eY=1106958.0">길찾기</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="locaBtn">
                                    <a href="http://kko.to/s_U7t9VDj" target="_blank" className="detailBtn"><i></i>자세히보기</a>
                                    <a href="javascript:onClick=openwin('print_map.jsp','','width=900,height=900')"
                                       className="printBtn"><i></i>인쇄하기</a>
                                </div>
                            </div>

                            <div className="loca_infocon">
                                <div className="loca_info">
                                    <p><strong>주소.</strong>서울특별시 금천구 가산디지털1로 212, 910호(가산동, 코오롱 애스턴)
                                        sales@ubcn.co.kr</p>
                                    <p className="ls0"><strong>전화.</strong>02-6458-2000</p>
                                    <p className="ls0"><strong>팩스.</strong>02-6458-2001</p>
                                </div>
                                <ul className="loca_icon">
                                    <li><strong>지하철</strong>가산디지털단지역 7호선 6번에서 도보로 약 400M 앞</li>
                                    <li><strong>버스</strong>일반버스 : 21, 7, 75 / 간선버스 : 652, 571</li>
                                    <li><strong>주차안내</strong>코오롱애스턴 건물 주차장 이용</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}