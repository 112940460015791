import React from "react";
import {useParams} from "react-router-dom";

export default function About() {
    let params = useParams();

    return (
        <>
            <>
                <link rel="stylesheet" type="text/css" href="/comm/css/sub.css"/>
                <div id="visual" className="subtop_product" style={{opacity: "1"}}>
                    <p></p>
                    <h3><span>서비스</span>SERVICE</h3>
                </div>

                <div id="contents" style={{opacity: "1"}}>
                    <div id="sticker">
                        <article className="NavMenu">
                            <div>
                                <nav className="mobile_snb">
                                    <div className="tbox clearfix">
                                        <a href="/" className="home"></a>
                                        <div className="box dep1">
                                            <div className="dropLst">
                                                <a href="#" className="txt title">회사소개</a>
                                                <div className="dlst">
                                                    <ul>
                                                        <li><a href="/product/total" className="">제품</a></li>
                                                        <li><a href="/service/umms" className="">서비스</a></li>
                                                        <li><a href="/company/about" className="">회사소개</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box dep2">
                                            <div className="dropLst">
                                                <a href="#" className="txt title">{params.type.toUpperCase()}</a>
                                                <div className="depth2">
                                                    <ul>
                                                        <li>
                                                            <a href={"/company/about"} className={"on"}>기업개요</a>
                                                        </li>
                                                        <li>
                                                            <a href={"/company/history"}>연혁</a>
                                                        </li>
                                                        <li>
                                                            <a href={"/company/partner"}>고객사</a>
                                                        </li>
                                                        <li>
                                                            <a href={"/company/location"}>찾아오시는 길</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </article>
                    </div>
                    <div className="contentArea_wide">
                        <div className="tit_area">
                            <div className="location">
                                <a href="/"><img src="/img/lnbHome.png" alt="Home"/></a><img src="/img/lnbArrow.png"
                                                                                             alt=">"/><a
                                href="about.jsp" className="here_link">회사소개</a><img src="/img/lnbArrow.png" alt=">"/>
                                기업개요
                            </div>
                            <h3 className="subcon_title">기업개요</h3>
                        </div>
                        <div className="contents_in">
                            <div className="about_con width1180">
                                <div className="about_top">
                                    <h4 className="sub_title">SK하이닉스 - (주)하이스마텍으로 이어온 단말기개발 기술력을 바탕으로</h4>
                                    <p className="sub_txt">다양한 IC카드단말기 라인업과 <span
                                        className="ls0">U-Payment</span> 결제솔루션을 중심으로 고객과 함께 성장하고
                                        <br/> 변화에 적응하는 회사로 성장해 나가겠습니다.</p>
                                    <p className="sub_txt">&nbsp;</p>
                                    <p className="sub_txt">&nbsp;</p>
                                    <p style={{width: "100%"}}>
                                        <img src="/data/img/20210907145359_sdqsbrwp.jpg"
                                             alt="about_img01.jpg"
                                             style={{width: "50%", height:"auto"}}/>
                                        <img
                                            src="/data/img/20210907145400_auuxuyie.jpg" alt="about_img02.jpg"
                                            style={{width: "50%", height: "auto"}}/>
                                    </p>
                                    <p><br/></p>
                                    <p><br/></p>
                                    <div className="about_info">
                                        <dl>
                                            <dt>대표자</dt>
                                            <dd>김대식</dd>
                                        </dl>
                                        <dl>
                                            <dt>설립일</dt>
                                            <dd>2008년 07월 30일</dd>
                                        </dl>
                                        <dl>
                                            <dt>기업형태</dt>
                                            <dd>벤처기업, 이노비즈, 청년친화강소기업</dd>
                                        </dl>
                                        <dl>
                                            <dt>사업분야</dt>
                                            <dd>NFC/결제/AFC 단말기, 무인결제솔루션, 무인매장관리시스템</dd>
                                        </dl>
                                        <dl>
                                            <dt>회사위치</dt>
                                            <dd>서울특별시 금천구 가산디지털1로 212, 910호 (가산동, 코오롱애스턴)</dd>
                                        </dl>
                                    </div>
                                    <div className="about_busi">
                                        <h5 className="sub_stitle bold">주요 사업 영역</h5>
                                        <div className="about_busi_in">
                                            <h5>OUR <span className="bold">BUSINESS</span></h5>
                                            <div className="about_busiList">
                                                <dl>
                                                    <dt><i></i></dt>
                                                    <dd>
                                                        <h6>Membership Charging <br/>Payment service</h6>
                                                        <p>멤버십 충전/지불 서비스</p>
                                                    </dd>
                                                </dl>
                                                <dl>
                                                    <dt><i></i></dt>
                                                    <dd>
                                                        <h6>Unmanned store Solution</h6>
                                                        <p>무인매장 솔루션</p>
                                                    </dd>
                                                </dl>
                                                <dl>
                                                    <dt><i></i></dt>
                                                    <dd>
                                                        <h6>Vending machine</h6>
                                                        <p>자율판매기</p>
                                                    </dd>
                                                </dl>
                                                <dl>
                                                    <dt><i></i></dt>
                                                    <dd>
                                                        <h6>Access authentication</h6>
                                                        <p>출입인증</p>
                                                    </dd>
                                                </dl>
                                                <dl>
                                                    <dt><i></i></dt>
                                                    <dd>
                                                        <h6>NFC Device</h6>
                                                        <p className="ls0">Near Field Communication</p>
                                                    </dd>
                                                </dl>
                                                <dl>
                                                    <dt><i></i></dt>
                                                    <dd>
                                                        <h6>AFC Device</h6>
                                                        <p className="ls0">Automatic Fare Collection</p>
                                                    </dd>
                                                </dl>
                                                <dl>
                                                    <dt><i></i></dt>
                                                    <dd>
                                                        <h6>Banking module</h6>
                                                    </dd>
                                                </dl>
                                                <dl>
                                                    <dt><i></i></dt>
                                                    <dd>
                                                        <h6>Vending Payment</h6>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
}