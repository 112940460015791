import {useParams} from "react-router-dom";
import $ from "jquery";
import React, {useEffect, useState} from "react";
import ProductList from "../../resources/data/product/productList.json";
import Parser from "html-react-parser";

export default function ProductDetail() {
    let [productInfo, setProductInfo] = useState(null);
    let param = useParams();
    useEffect(() => {
        setProductInfo(ProductList.products.filter((item) => item.prdcode === param.code))
    }, []);

    function adjustStyle(width) {
        if (width < 981) {
            $(".depth2").addClass("dlst");
            $(".depth2").removeClass("depth2");
        } else {
            $(".depth2").removeClass("dlst");
            $(".dep2 .dropLst > div").addClass("depth2");
        }
    }
    $(function () {
        adjustStyle($(this).width());
        $(window).resize(function () {
            adjustStyle($(this).width());
        });
    });
    function chgImage(idx, prdcode) {
        if (idx === "1") {
            document.prdimg.style = "background-image:url('/data/product2/" + prdcode + "_M1.jpg')";
        }else if(idx === "2"){
            document.prdimg.style = "background-image:url('/data/product2/" + prdcode + "_M2.jpg')";
        }else if (idx === "3") {
            document.prdimg.style = "background-image:url('/data/product2/" + prdcode + "_M3.jpg')";
        }
    }
    return (
        <>
            <link rel="stylesheet" type="text/css" href="/data/product2/skin/prdBasic/style.css"/>
            <link rel="stylesheet" type="text/css" href="/comm/css/sub.css"/>
            <div id="visual" className="subtop_product" style={{opacity: "1"}}>
                <p></p>
                <h3><span>제품</span>PRODUCT</h3>
            </div>

            {productInfo && productInfo.map((item) => (
                <div id="contents" style={{opacity: "1"}} key={item.prdcode}>
                    <div className="contentArea_wide">
                        <div className="contents_in">
                            <div className="width1180">
                                <div></div>

                                <div className="pro_view">
                                    <div className="pro_img ">
                                        <img
                                            src="/data/product2/skin/prdBasic/image/p_blank_img.png"
                                            style={{backgroundImage: `url(/data/product2/${item.prdcode}_M1.jpg)`}}
                                            name="prdimg"
                                            alt={item.prdname} className="bimg"/>
                                        <ul>
                                            <li>
                                                <img src="/data/product2/skin/prdBasic/image/p_blank_img.png"
                                                     style={{backgroundImage: `url(/data/product2/${item.prdcode}_S1.jpg)`}}
                                                     onMouseOver={() => chgImage('1', `${item.prdcode}`)}
                                                     alt={`${item.prdname} 1번 상세이미지 썸네일`}/>
                                            </li>
                                            <li>
                                                <img src="/data/product2/skin/prdBasic/image/p_blank_img.png"
                                                     style={{backgroundImage: `url(/data/product2/${item.prdcode}_S2.jpg)`}}
                                                     onMouseOver={() => chgImage('2', `${item.prdcode}`)}
                                                     alt={`${item.prdname} 2번 상세이미지 썸네일`}/>
                                            </li>
                                            <li>
                                                <img src="/data/product2/skin/prdBasic/image/p_blank_img.png"
                                                     style={{backgroundImage: `url(/data/product2/${item.prdcode}_S3.jpg)`}}
                                                     onMouseOver={() => chgImage('3', `${item.prdcode}`)}
                                                     alt={`${item.prdname} 3번 상세이미지 썸네일`}/>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="pro_txt">
                                        <h4>{item.prdname}</h4>
                                        <table className="view_data" summary="제품상세정보">
                                            <caption>제품상세정보테이블</caption>
                                            <tbody>
                                            <tr>
                                                <td>{item.info_value1}</td>
                                            </tr>
                                            <tr>
                                                <td>{item.info_value2}</td>
                                            </tr>
                                            <tr>
                                                <td>{item.info_value3}</td>
                                            </tr>
                                            <tr>
                                                <td>{item.info_value4}</td>
                                            </tr>
                                            <tr>
                                                <td>{item.info_value5}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        {
                                            item.upfile1 ?
                                                <div className="downArea">
                                                    <p><img src="/data/product2/skin/prdBasic/image/file_icon.gif" alt="file1"/>
                                                        <a style={{color: "#666"}} href={`https://image.ubcn.co.kr/download/${item.upfile1_name}`}
                                                           target={"_blank"} rel="noreferrer" download={`${item.upfile1_name}`}>
                                                            {item.upfile1_name}
                                                        </a>
                                                    </p><span>첨부파일을 클릭하시면 다운받으실 수 있습니다!</span>
                                                </div>
                                                :
                                                <></>
                                        }
                                        <div className="pro_btn"><a
                                            href={"javascript:history.back();"}
                                            className="listBtn">목록으로</a>
                                        </div>
                                    </div>
                                </div>

                                <h5 className="sub_stitle">제품상세정보</h5>
                                <div className="proView_in">
                                    <h5>Specification</h5>
                                    <div>
                                        {Parser(item.content)}
                                    </div>
                                </div>
                                <div className="proView_in">
                                    <h5>적용시장</h5>
                                    <div>
                                        {Parser(item.mcontent)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))};
        </>
    );
}
