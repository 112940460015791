import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import API from '../../util/api.js';
import $ from "jquery";
import Parser from "html-react-parser";
import Loader from '../../components/loader';
import CustomerData from "../../resources/data/customer/customer.json"

export default function Notice() {
    let [items, setItems] = useState(null);
    let params = useParams();
    const [loading, setLoading] = useState(false)

    //접근 가능한 페이지 지정
    useEffect(() => {
        async function callAPI() {
            const res = await API.get("/list/noticeList")
            setItems(res.data)
            //console.log(res.data)
            /*setItems(CustomerData.customer.notice.sort(function (a, b) {
                if (a.seq > b.seq) {
                    return -1;
                } else if (a.seq > b.seq) {
                    return 1;
                }
                return 0;
            }))*/
            setLoading(false)
        }
        setLoading(true)
        callAPI()
    }, []);

    $(function () {
        $('.notice_title').on("click", function (e) {
            e.preventDefault();
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).next().hide();
            } else {
                $(this).addClass('active');
                $(this).next().show();
            }
        });
    });

    return (
        <>
            {loading ?
                <Loader type="spin" background="rgb(6,6,6)" color="#fff" message={'로딩중입니다.'}
                        position={"loading-position-absolute"}/> : null
            }
            <link rel="stylesheet" type="text/css" href="/data/bbs/skin/bbsBasic/style.css"/>
            <link rel="stylesheet" type="text/css" href="/comm/css/sub.css"/>
            <div id="visual" className="subtop_product" style={{opacity: "1"}}>
                <p></p>
                <h3><span>고객지원</span>CS CENTER</h3>
            </div>

            <div id="contents" style={{opacity: "1"}}>
                <div id="sticker">
                    <article className="NavMenu">
                        <div>
                            <nav className="mobile_snb">
                                <div className="tbox clearfix">
                                    <a href="/" className="home"></a>
                                    <div className="box dep1">
                                        <div className="dropLst">
                                            <a href="#" className="txt title">고객지원</a>
                                            <div className="dlst">
                                                <ul>
                                                    <li><a href="/product/product" className="">제품</a></li>
                                                    <li><a href="/service/umms" className="">서비스</a></li>
                                                    <li><a href="/customer/notice" className=" on">고객지원</a></li>
                                                    <li><a href="/company/about" className="">회사소개</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box dep2">
                                        <div className="dropLst">
                                            <a href="#" className="txt title">{params.type.toUpperCase()}</a>
                                            <div className="depth2">
                                                <ul>
                                                    <li><a href="/customer/notice" className="on">공지사항</a></li>
                                                    <li><a href="/customer/qna">문의하기</a></li>
                                                    <li><a href="/customer/result">설치사례</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </article>
                </div>
                <div className="contentArea_wide">
                    <div className="tit_area">
                        <div className="location">
                            <a href="/"><img src="/img/lnbHome.png" alt="Home"/></a>
                            <img src="/img/lnbArrow.png" alt=">"/>
                            <a href="/service/umms" className="here_link">고객지원</a>
                            <img src="/img/lnbArrow.png" alt=">"/>공지사항
                        </div>
                        <h3 className="subcon_title">공지사항</h3>
                    </div>
                    <div className="contents_in">
                        <div className="width1180">
                            <table className="bbs_con" summary="게시물 목록을 보여줍니다.">
                                <caption className="blind">게시물 목록</caption>
                                <thead>
                                <tr key={""}>
                                    <th width="10%" scope="col">번호</th>
                                    <th>제목</th>
                                    <th width="15%" scope="col">작성일</th>
                                </tr>
                                </thead>
                                <tbody>
                                {items && items.data.map(item => (
                                    <>
                                        <tr className={`notice_title`} key={item.noticeSeq}>
                                            <td className="pc_line">{item.noticeSeq}</td>
                                            <td className="left">
                                                <div className="pc_line">
                                                    <a>{Parser(item.subject)}</a>
                                                </div>
                                                <div className="mo_line">
                                                    <p className="list_cate"></p>
                                                    <p className="subject">
                                                        <a>{Parser(item.subject)}</a></p>
                                                    <ul className="writer">
                                                        <li>{item.regDate}</li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td className="pc_line">{item.regDate}</td>
                                        </tr>
                                        <tr className="notice_content" style={{display: "none"}}>
                                            <td className={"pc_line"}>&nbsp;</td>
                                            <td className="view_content" colSpan="3">
                                                {Parser(item.content)}
                                            </td>
                                            <td className={"pc_line"}>&nbsp;</td>
                                        </tr>
                                    </>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
        ;
}
