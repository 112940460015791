import {useParams, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Sitemap from "./Sitemap";
import Privacy from "./Privacy";

export default function Customer() {
    let navigate = useNavigate();
    let params = useParams();
    //페이지 접근 가능한 페이지 지정
    const [pages] = useState(["sitemap", "privacy"]);
    useEffect(() => {
        if (!pages.includes(params.type.toLowerCase())) {
            navigate("/other/sitemap")
        }
    }, []);

    return (
        <>
            {params.type.toLowerCase() === "sitemap" ? <Sitemap/> : null}
            {params.type.toLowerCase() === "privacy" ? <Privacy/> : null}
        </>
    )
        ;
}