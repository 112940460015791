import {useParams, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Notice from "./Notice";
import Qna from "./Qna";
import Result from "./Result";
import Download from "./Download";

export default function Customer() {
    let navigate = useNavigate();
    let params = useParams();
    //페이지 접근 가능한 페이지 지정
    const [pages] = useState(["notice", "qna", "result", "download"]);
    useEffect(() => {
        if (!pages.includes(params.type.toLowerCase())) {
            navigate("/customer/notice")
        }
    }, []);

    return (
        <>
            {params.type.toLowerCase() === "notice" ? <Notice/> : null}
            {params.type.toLowerCase() === "qna" ? <Qna/> : null}
            {params.type.toLowerCase() === "result" ? <Result/> : null}
            {params.type.toLowerCase() === "download" ? <Download/> : null}
        </>
    )
        ;
}