import $ from "jquery"
import "../../resources/css/404page.css"
export default function NotFound(){
    $(function () {
        //Layout 동기로 제거
        $("#header").hide();
        $("#footer").hide();
    });
    return(
        <>
            <section className="page_404">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 ">
                            <div className="col-sm-10 col-sm-offset-1  text-center">
                                <div className="four_zero_four_bg">
                                    <h1 className="text-center ">404</h1>
                                </div>
                                <div className="contant_box_404">
                                    <h3 className="h2">
                                        페이지가 존재하지 않습니다.
                                    </h3>
                                    <a href="/" className="link_404">메인으로 돌아가기</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}