import React, {useState} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";

import Layout from "./pages/common/Layout";
import Index from "./pages/Index";
import Product from "./pages/product/Product";
import ProductDetail from "./pages/product/ProductDetail";
import Customer from "./pages/customer/Customer";
import Service from "./pages/service/Service";
import NotFound from "./pages/common/NotFound";
import Company from "./pages/company/Company";
import Other from "./pages/other/Other";

/*
TODO: react-router-dom v6.0↑ 으로 기존 Switch 에서 Routes 로 변경 - twkim
 * index.jsx 페이지는 전처리 기능으로 활용성 높이고자 App 에서 Handler
 * 
 */

function App() {
  return (
      <div id="wrap" className="wrap">
      <BrowserRouter>
          <Layout>
              <Routes>
                  <Route path="/" element={<Index/>} />
                  <Route path="product">
                      <Route path=":type" element={<Product/>}/>
                  </Route>
                  <Route path="productDetail">
                      <Route path=":code" element={<ProductDetail/>}/>
                  </Route>
                  <Route path="service">
                      <Route path=":type" element={<Service/>}/>
                  </Route>
                  <Route path="customer">
                      <Route path=":type" element={<Customer/>}/>
                  </Route>
                  <Route path="company">
                      <Route path=":type" element={<Company/>}/>
                  </Route>
                  <Route path="other">
                      <Route path=":type" element={<Other/>}/>
                  </Route>
                  <Route path="/*" element={<NotFound/>} />
              </Routes>
          </Layout>
      </BrowserRouter>
      </div>
  );
}
export default App;
