import Logo2 from "../../resources/img/logo2.png";
import TopClose from "../../resources/img/top_close.png";
import React from "react";

export default function MobileMenuArea() {
  return (
      <div id="menuArea">
        <div className="menuList">
          <h1>
            <a href="/" className="menu_logo">
              <img src={Logo2} alt="로고"/>
            </a>
            <button type="button" className="btnMenu_mClose" title="close">
              <img src={TopClose} alt="Close"/>
            </button>
          </h1>
          <ul className="list">
            <li className="icon01">
              <a className="dropdown-toggle">제품<span
                  className="icon"></span></a>
              <ul className="sMenu">
                <li><a href="/product/total">Total</a></li>
                <li><a href="/product/vending">Vending</a></li>
                <li><a href="/product/access_control">Access Control</a></li>
                <li><a href="/product/ic_reader">IC Reader</a></li>
                <li><a href="/product/rf_reader">RF Reader</a></li>
                <li><a href="/product/multipad">MultiPAD</a></li>
                <li><a href="/product/afc">AFC</a></li>
              </ul>
            </li>
            <li className="icon01">
              <a className="dropdown-toggle">서비스<span
                  className="icon"></span></a>
              <ul className="sMenu">
                <li><a href="/service/umms">무인기기 관리</a></li>
                <li><a href="/service/ecps">사원카드 결제</a></li>
                <li><a href="/service/usms">신용카드 출입</a></li>
                <li><a href="/service/api">데이터 연동</a></li>
              </ul>
            </li>
            <li className="icon01">
              <a className="dropdown-toggle">고객지원<span className="icon"></span></a>
              <ul className="sMenu">
                <li><a href="/customer/notice">공지사항</a></li>
                <li><a href="/customer/qna">소통의 장</a></li>
                <li><a href="/customer/result">설치사례</a></li>
              </ul>
            </li>
            <li className="icon01">
              <a className="dropdown-toggle">회사소개<span className="icon"></span></a>
              <ul className="sMenu">
                <li><a href="/company/about">기업개요</a></li>
                <li><a href="/company/history">연혁</a></li>
                <li><a href="/company/partner">고객사</a></li>
                <li><a href="/company/location">찾아오시는 길</a></li>
              </ul>
            </li>
            {/* 2023.06.07 twkim P&P사업부 요청에 의해 내비게이션 내용 추가 */}
            <li className="icon01">
              <a className="dropdown-toggle">온라인 신청서<span
                  className="icon"></span></a>
              <ul className="sMenu">
                <li><a href="https://biz.ubcn.co.kr/biz_regist"
                    /*onClick={() => termRegist()} */ rel="noreferrer"
                       target="_blank">신규가맹신청</a></li>
                <li><a href="https://biz.ubcn.co.kr/term_regist"
                    /*onClick={() => termRegist()} */ rel="noreferrer"
                       target="_blank">단말기신청</a></li>
                <li><a href="https://biz.ubcn.co.kr/term_change"
                    /*onClick={() => termChange()} */ rel="noreferrer"
                       target="_blank">명의변경신청</a></li>
                <li><a href="https://biz.ubcn.co.kr/term_quit"
                    /*onClick={() => termQuit()}*/ rel="noreferrer"
                       target="_blank">해지신청</a></li>
                <li><a href="https://biz.ubcn.co.kr/npay_regist"
                    /*onClick={() => termNaver()}*/ rel="noreferrer"
                       target="_blank">네이버페이 가맹신청</a></li>
                <li><a href="https://biz.kakaopay.com/applications"
                    /*onClick={() => termNaver()}*/ rel="noreferrer"
                       target="_blank">카카오페이 가맹신청</a></li>
              </ul>
            </li>
          </ul>
          {/*<div className="molink"><a href="https://biz.ubcn.co.kr/term_regist" rel="noreferrer" target="_blank"
                                           rel="noreferrer">
                    단말기 신청서</a></div>
                <div className="molink"><a href="https://biz.ubcn.co.kr/term_change" rel="noreferrer" target="_blank"
                                           rel="noreferrer">
                    단말기 명의변경 신청서</a></div>
                <div className="molink"><a href="https://biz.ubcn.co.kr/npay_regist" rel="noreferrer" target="_blank"
                                           rel="noreferrer">
                    네이버페이 가맹신청서</a></div>*/}
        </div>
      </div>
  );
}
