import { useParams } from "react-router-dom";
import $ from "jquery";
import BlankImage from "../../resources/img/blank_img.png";
import React, {useEffect, useState} from "react";
import ProductList from "../../resources/data/product/productList.json";

export default function Product() {
    let [items, setItems] = useState(null);
    let params = useParams();

    //제품 페이지 접근 가능한 페이지 지정
    const [pages] = useState(["total", "vending", "access_control", "ic_reader", "rf_reader", "multipad", "afc"]);
    useEffect(() => {
        if(pages.includes(params.type.toLowerCase())){
            params.type.toLowerCase() === 'total' ?
                setItems(ProductList.products.sort(function (a,b){
                    if(a.prdcode > b.prdcode) {
                        return -1;
                    }else if(a.prdcode > b.prdcode){
                        return 1;
                    }
                    return 0;
                })) :
                setItems(ProductList.products.sort(function (a,b){
                    if(a.prdcode > b.prdcode) {
                        return -1;
                    }else if(a.prdcode > b.prdcode){
                        return 1;
                    }
                    return 0;
                }).filter((item) => item.prdtype === params.type.toLowerCase()))
        }else{
            window.location.href("/product/total")
        }
        imgHandler(params.type);
    }, []);

    function imgHandler(params) {
        switch (params) {
            case 'total':
                $('.pro_list > dl').show();
                $('.depth2 > ul > li > a').removeClass("on");
                $('.depth2 > ul > li:eq(0) > a').addClass("on");
                break;
            case 'vending':
                $('.pro_list > dl').hide();
                $('.vending').show();
                $('.depth2 > ul > li > a').removeClass("on");
                $('.depth2 > ul > li:eq(1) > a').addClass("on");
                break;
            case 'access_control':
                $('.pro_list > dl').hide();
                $('.access_control').show();
                $('.depth2 > ul > li > a').removeClass("on");
                $('.depth2 > ul > li:eq(2) > a').addClass("on");
                break;
            case 'ic_reader':
                $('.pro_list > dl').hide();
                $('.ic_reader').show();
                $('.depth2 > ul > li > a').removeClass("on");
                $('.depth2 > ul > li:eq(3) > a').addClass("on");
                break;
            case 'rf_reader':
                $('.pro_list > dl').hide();
                $('.rf_reader').show();
                $('.depth2 > ul > li > a').removeClass("on");
                $('.depth2 > ul > li:eq(4) > a').addClass("on");
                break;
            case 'multipad':
                $('.pro_list > dl').hide();
                $('.multipad').show();
                $('.depth2 > ul > li > a').removeClass("on");
                $('.depth2 > ul > li:eq(5) > a').addClass("on");
                break;
            case 'afc':
                $('.pro_list > dl').hide();
                $('.afc').show();
                $('.depth2 > ul > li > a').removeClass("on");
                $('.depth2 > ul > li:eq(6) > a').addClass("on");
                break;
        }
    }

    return (
        <>
            <link rel="stylesheet" type="text/css" href="/data/product2/skin/prdBasic/style.css"/>
            <link rel="stylesheet" type="text/css" href="/comm/css/sub.css"/>
            <div id="visual" className="subtop_product" style={{opacity: "1"}}>
                <p></p>
                <h3><span>제품</span>PRODUCT</h3>
            </div>

            <div id="contents" style={{opacity: "1"}}>
                <div id="sticker">
                    <article className="NavMenu">
                        <div>
                            <nav className="mobile_snb">
                                <div className="tbox clearfix">
                                    <a href="/" className="home"></a>
                                    <div className="box dep1">
                                        <div className="dropLst">
                                            <a href="#" className="txt title">제품</a>
                                            <div className="dlst">
                                                <ul>
                                                    <li><a
                                                        href="/product/total"
                                                        className=" on">제품</a></li>
                                                    <li><a href="../service/umms.php" className="">서비스</a></li>
                                                    <li><a href="../company/about.jsp" className="">회사소개</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box dep2">
                                        <div className="dropLst">
                                            <a href="#" className="txt title">{params.type.toUpperCase()}</a>
                                            <div className="depth2">
                                                <ul>
                                                    <li>
                                                        <a href={"/product/total"} className="on">Total</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/product/vending"}>Vending</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/product/access_control"}>Access Control</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/product/ic_reader"}>IC Reader</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/product/rf_reader"}>RF Reader</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/product/multipad"}>MultiPAD</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/product/afc"}>AFC</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </article>
                </div>

                <div className="contentArea_wide">
                    <div className="tit_area">
                        <div className="location">
                            <a href="/"><img src="/img/lnbHome.png" alt="Home"/></a>
                            <img src="/img/lnbArrow.png" alt=">"/>
                            <a href="/product/total" className="here_link">제품소개</a><img
                            src="/img/lnbArrow.png" alt=">"/>{params.type.toUpperCase()}
                        </div>
                        <h3 className="subcon_title">{params.type.toUpperCase()}</h3>
                    </div>
                    <div className="contents_in">
                        <div className="width1180">
                            <div className="pro_list">
                                {items && items.map(item => (
                                    <dl className={`${item.prdtype}`} key={item.prdimg_R}>
                                        {/*제품 상세정보 페이지로 이동*/}
                                        <a href={`/productDetail/${item.prdcode}`}>
                                            <dt>
                                                <img src={BlankImage}
                                                     style={{backgroundImage: `url(/data/product2/${item.prdimg_R})`}}
                                                     alt=""/></dt>
                                            <dd>
                                                <p className="title">{item.prdname}</p>
                                            </dd>
                                        </a>
                                    </dl>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}