import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import $ from "jquery";
import Loader from "../../components/loader";

export default function Qna() {
    let params = useParams();
    const [loading, setLoading] = useState(false)

    function addHyphenPhoneNo(obj) {
        var number = obj.value.replace(/[^0-9]/g, "");
        var phoneNo = '';
        if (number.length < 4) {
            return number;
        } else if (number.length <= 5) {
            phoneNo += number.substr(0, 3);
            phoneNo += '-';
            phoneNo += number.substr(3);
        } else if (number.length < 10) {
            phoneNo += number.substr(0, 3);
            phoneNo += '-';
            phoneNo += number.substr(3, 4);
            phoneNo += '-';
            phoneNo += number.substr(7);
        } else {
            phoneNo += number.substr(0, 3);
            phoneNo += '-';
            phoneNo += number.substr(3, 4);
            phoneNo += '-';
            phoneNo += number.substr(7);
        }
        obj.value = phoneNo;
    }

    function confirmQNA() {
        setLoading(true);

        var data = new Object();
        var subData = new Object();
        var arr = new Array();

        subData.qnaName= $("input[name=qnaName]").val();
        subData.qnaPhone= $("input[name=qnaPhone]").val();
        subData.qnaEmail= $("input[name=qnaEmail]").val();
        subData.qnaTitle= $("input[name=qnaTitle]").val();
        subData.qnaContent= $("#qnaContent").val();
        arr.push(subData);

        //수신자 설정(API에도 동일하게 설정해야함)
        data.target="sales";
        data.type="QNA";
        data.detailInfo = arr;

        console.log(data);

        $.ajax({
            cache: false,
            url: "https://devapi.ubcn.co.kr/common/smtp",
            //url: "http://localhost:17881/common/smtp",
            crossDomain: true,
            type: 'POST',
            data: JSON.stringify(data),
            dataType: "json",
            headers: {
                "accept": "application/json",
                "Access-Control-Allow-Origin":"*",
                "Content-Type":"application/json;charset=utf-8;"
            },
            success: function () {
                alert('문의가 등록되었습니다.');
                setLoading(false);
                window.location.reload();
            }, // success
            error: function (xhr, status) {
                alert(xhr + " : " + status);
                setLoading(false);
            }
        });
    }

    return (
        <>
            {loading ?
                <Loader type="spin" background="rgb(6,6,6)" color="#fff" message={'전송중입니다.'}
                        position={"loading-position-absolute"}/> : null
            }

            <link rel="stylesheet" type="text/css" href="/data/form/skin/formBasic/style.css"/>
            <link rel="stylesheet" type="text/css" href="/comm/css/sub.css"/>
            <link rel="stylesheet" type="text/css" href="//code.jquery.com/ui/1.11.4/themes/smoothness/jquery-ui.css"/>
            <script type="text/javascript"
                    src="//code.jquery.com/ui/1.11.4/jquery-ui.min.js"></script>
            <script type="text/javascript" src="/comm/js/upload_file_limit.js"></script>
            <script src="http://dmaps.daum.net/map_js_init/postcode.v2.js"></script>
            <script charSet="UTF-8" type="text/javascript"
                    src="//t1.daumcdn.net/postcode/api/core/220630/1656574735251/220630.js"></script>
            <div id="visual" className="subtop_product" style={{opacity: "1"}}>
                <p></p>
                <h3><span>고객지원</span>CS CENTER</h3>
            </div>

            <div id="contents" style={{opacity: "1"}}>
                <div id="sticker">
                    <article className="NavMenu">
                        <div>
                            <nav className="mobile_snb">
                                <div className="tbox clearfix">
                                    <a href="/" className="home"></a>
                                    <div className="box dep1">
                                        <div className="dropLst">
                                            <a href="#" className="txt title">고객지원</a>
                                            <div className="dlst">
                                                <ul>
                                                    <li><a href="/product/product" className="">제품</a></li>
                                                    <li><a href="/service/umms" className="">서비스</a></li>
                                                    <li><a href="/customer/notice" className="on">고객지원</a></li>
                                                    <li><a href="/company/about" className="">회사소개</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box dep2">
                                        <div className="dropLst">
                                            <a href="#" className="txt title">{params.type.toUpperCase()}</a>
                                            <div className="depth2">
                                                <ul>
                                                    <li><a href="/customer/notice">공지사항</a></li>
                                                    <li><a href="/customer/qna" className="on">문의하기</a></li>
                                                    <li><a href="/customer/result">설치사례</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </article>
                </div>
                <div className="contentArea_wide">
                    <div className="tit_area">
                        <div className="location">
                            <a href="/"><img src="/img/lnbHome.png" alt="Home"/></a>
                            <img src="/img/lnbArrow.png" alt=">"/>
                            <a href="/service/umms" className="here_link">고객지원</a>
                            <img src="/img/lnbArrow.png" alt=">"/>소통의 장
                        </div>
                        <h3 className="subcon_title">소통의 장</h3>
                        <p className="sub_txt">자판기 사업을 진행하면서 궁금했던 사항이나 필요한 정보, 알고 싶은 내용 등이 있으면 자유롭게 적어주세요.
                            <br/>* 답변은 공식 블로그에서 확인해주세요.
                        </p>
                        <br/>
                    </div>
                    <div className="contents_in">
                        <div className="width1180">
                            <div className="contents_in">
                                <div className="width1180">
                                    <form id="formData" name="formData">
                                        <div className="contact_input">
                                            <table width="100%" className="form_table" summary="폼메일 목록 보여줍니다.">
                                                <caption>폼메일 목록</caption>
                                                <tbody>
                                                <tr>
                                                    <th width="15%" scope="row">이름 *</th>
                                                    <td><input type="text" id="1_f0_0" name="qnaName"
                                                               className="input" size="30"
                                                               title="text"/></td>
                                                </tr>
                                                <tr>
                                                    <th width="15%" scope="row">휴대전화번호 *</th>
                                                    <td>
                                                        <input type="text" id="1_f1_1" name="qnaPhone"
                                                               onKeyUp={()=>addHyphenPhoneNo(this)}
                                                               className="input" minLength="11" maxLength="13"
                                                               title="휴대폰 번호 11자리를 입력해주세요" onlynum="true"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="15%" scope="row">이메일 *</th>
                                                    <td><input type="email" id="1_f2_1" name="qnaEmail"
                                                               className="input" size="50"
                                                               title="주소입력"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="15%" scope="row">제목 *</th>
                                                    <td><input type="text" id="1_f3_0" name="qnaTitle"
                                                               className="input" size="50"
                                                               title="text"/></td>
                                                </tr>
                                                <tr>
                                                    <th width="15%" scope="row">문의내용 *</th>
                                                    <td><textarea id="qnaContent" name="qnaContent" rows="10"
                                                                  className="textarea"
                                                                  title="textarea"></textarea></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div className="btn_area">
                                                <input type="button" value="문의하기" className="btn_confirm"
                                                       onClick={()=>confirmQNA()}/>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
        ;
}
